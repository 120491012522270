import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import RecordsPerPageSelect from '~/features/search/components/RecordsPerPageSelect';
import { useFetchLogTypes } from '~/hooks/useFetchLogTypes';
import { useAuditLog } from './useAuditLog';
import Pagination from '~/features/search/components/Pagination';
import { Table } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { useTranslateEventLogTypes } from '~/hooks/useTranslateEventLogTypes';
import { Link } from 'react-router-dom';
import { MapUserLevels } from '~/features/profile/Profile';
import type { EventLogTypeDto } from '~/lib/dtos/common/EventLogTypeDto';
import { formatDate } from '~/lib/utils/formatDates/formatDates';
import type { UserGroupDto } from '~/lib/dtos/UserGroupDto';
import { useModal } from '~/hooks/useModal';
import { Modal } from '~/components/Modal/Modal';
import { z } from 'zod';

const redirectToTicketsList: EventLogTypeDto[] = [
  'REFUND',
  'RESEND_TICKET',
  'ADD_NOTE_TO_TICKET',
  'SEND_RECEIPT',
];

const redirectToCustomerProfileList: EventLogTypeDto[] = [
  'BLOCK_CUSTOMER',
  'UNBLOCK_CUSTOMER',
  'ADD_NOTE_TO_CUSTOMER',
  'DECREASE_WARNING',
];

const redirectToUserProfileList: EventLogTypeDto[] = [
  'CREATE_USER_PROFILE',
  'EDIT_USER_PROFILE',
  'RESEND_PASSWORD',
  'REACTIVATE_USER_PROFILE',
  'DEACTIVATE_USER_PROFILE',
  'DELETE_USER',
  'CHANGE_USER_LEVEL',
];

const redirectToBulletins: EventLogTypeDto[] = [
  'PUBLISH_BULLETIN',
  'EDIT_BULLETIN',
];

const redirectToPopUp: EventLogTypeDto[] = [
  'UNBLOCK_AND_DECREASE_WARNINGS_PER_OPERATORS',
];

const userSchema = z.object({
  userId: z.object({
    value: z.number(),
  }),
  phoneNumber: z.object({
    value: z.string(),
  }),
});

const responseSchema = z.object({
  usersWithDecreasedWarnings: z.array(userSchema),
  usersUnblocked: z.array(userSchema),
});

const getRecordTypeBasedOnEventType = (
  type: EventLogTypeDto
): 'ticket' | 'customer' | 'user' | 'bulletin' | 'other' | null => {
  if (redirectToTicketsList.includes(type)) {
    return 'ticket';
  }

  if (redirectToCustomerProfileList.includes(type)) {
    return 'customer';
  }

  if (redirectToUserProfileList.includes(type)) {
    return 'user';
  }

  if (redirectToBulletins.includes(type)) {
    return 'bulletin';
  }

  if (redirectToPopUp.includes(type)) {
    return 'other';
  }

  return null;
};

const AuditLog = () => {
  const { t } = useTranslation();

  const { logTypesOptions, isLoading: logTypesLoading } = useFetchLogTypes();

  const { data, error, isLoading, searchCriteria, isFetching } = useAuditLog();

  const {
    endDate,
    type,
    limit,
    page,
    setEmail,
    email,
    setEndDate,
    setType,
    setLimit,
    setPage,
    setSortOrder,
    setStartDate,
    debouncedUsernameUpdate,
    startDate,
    debouncedEmailUpdate,
    setUserName,
    userName,
    setToDefault,
  } = searchCriteria;

  const emailRef = useRef<HTMLInputElement>(null);
  const userNameRef = useRef<HTMLInputElement>(null);

  const resetToDefaults = () => {
    setToDefault();
    if (!emailRef.current || !userNameRef.current) {
      return;
    }
    emailRef.current.value = '';
    userNameRef.current.value = '';
  };

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.value = email;
    }

    if (userNameRef.current) {
      userNameRef.current.value = userName;
    }
  }, [email, userName]);

  return (
    <div className="flex flex-col flex-1 gap-4">
      <div className="flex items-center justify-between">
        <div className="pt-2 text-xl font-semibold">
          {t('audit.audit-log-search')}
        </div>
        <button
          onClick={resetToDefaults}
          className="text-white bg-blue-600 hover:bg-blue-400 transition-all focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {t('common.reset-to-defaults')}
        </button>
      </div>

      <div className="flex items-center self-end gap-4">
        <div>
          <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
            {t('common.start-date')}
          </label>
          <DatePicker
            showTimeSelect
            dateFormat="d/M/yyyy, HH:mm"
            timeFormat="HH:mm"
            selected={startDate}
            onChange={(date) => {
              if (!date) {
                return;
              }
              setStartDate(date);
            }}
            calendarStartDay={1}
          />
        </div>
        <div>
          <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
            {t('common.end-date')}
          </label>
          <DatePicker
            showTimeSelect
            dateFormat="d/M/yyyy, HH:mm"
            timeFormat="HH:mm"
            selected={endDate}
            onChange={(date) => {
              if (!date) {
                return;
              }
              setEndDate(date);
            }}
            calendarStartDay={1}
          />
        </div>
        <RecordsPerPageSelect
          type="lg"
          setItemsPerPage={setLimit}
          itemsPerPage={limit}
        />
      </div>
      <div className="flex items-center gap-4">
        <div>
          <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
            {t('users.full-name')}
          </label>
          <input
            type="text"
            className="border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={t('users.full-name')}
            onChange={debouncedUsernameUpdate}
            ref={userNameRef}
          />
        </div>
        <div>
          <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
            {t('audit.actions')}
          </label>
          <Select
            placeholder="Type"
            isClearable
            className="w-60"
            value={logTypesOptions.find(({ value }) => value === type) ?? null}
            options={logTypesOptions}
            isLoading={logTypesLoading}
            onChange={(option) => {
              if (!option) {
                setType(null);
                return;
              }
              setType(option.value);
            }}
          />
        </div>
        <div>
          <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
            {t('users.email')}
          </label>
          <input
            type="text"
            className="border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={t('users.email')}
            onChange={debouncedEmailUpdate}
            ref={emailRef}
          />
        </div>
        {isFetching && (
          <div className="ml-auto">
            <ClipLoader />
          </div>
        )}
      </div>
      <Table className="border border-separate border-gray-300 rounded-md shadow-lg">
        <TableBody isLoading={isLoading} error={error} data={data} />
      </Table>
      <Pagination
        resultsNumber={data?.count ?? 0}
        onPageChange={(event) => {
          setPage(event.selected + 1);
        }}
        pageCount={data?.availablePages ?? 1}
        currentPage={page - 1}
      />
    </div>
  );
};

type TableBodyProps = Pick<
  ReturnType<typeof useAuditLog>,
  'data' | 'error' | 'isLoading'
>;

const TableBody = ({ data, isLoading, error }: TableBodyProps) => {
  const { t } = useTranslation();
  const [selectedReferences, setSelectedReferences] = useState<
    { userId: number; phoneNumber: string }[]
  >([]);

  const [modalIsVisible, toggleModalVisibility] = useModal();
  const { translateEvents } = useTranslateEventLogTypes();

  const handleOpenModal = (reference: string) => {
    try {
      const parsedReference = JSON.parse(reference);
      const validationResult = responseSchema.safeParse(parsedReference);

      if (validationResult.success) {
        const mappedReferences = [
          ...validationResult.data.usersWithDecreasedWarnings,
          ...validationResult.data.usersUnblocked,
        ].map(({ userId, phoneNumber }) => ({
          userId: userId.value,
          phoneNumber: phoneNumber.value,
        }));

        setSelectedReferences(mappedReferences);
      } else {
        console.error('Invalid reference structure', validationResult.error);
        setSelectedReferences([]);
      }
    } catch (error) {
      console.error('Failed to parse reference', error);
      setSelectedReferences([]);
    }

    toggleModalVisibility();
  };

  if (error) {
    return (
      <Table.Head className="text-center">
        <Table.HeadCell>{JSON.stringify(error)}</Table.HeadCell>
      </Table.Head>
    );
  }
  if (isLoading) {
    return (
      <Table.Head className="text-center">
        <Table.HeadCell>{t('common.loading')}</Table.HeadCell>
      </Table.Head>
    );
  }
  if (data?.logs.length === 0) {
    return (
      <div className="flex justify-center text-center">
        <Table.Head className="text-center">
          <Table.HeadCell>{t('customer.no-results')}</Table.HeadCell>
        </Table.Head>
      </div>
    );
  }
  return (
    <>
      <Table.Head className="bg-gray-100">
        <Table.HeadCell className="text-center">
          {t('users.full-name')}
        </Table.HeadCell>
        <Table.HeadCell className="text-center">
          {t('users.email')}
        </Table.HeadCell>
        <Table.HeadCell className="text-center">
          {t('event-log.time')}
        </Table.HeadCell>
        <Table.HeadCell className="text-center">
          {t('audit.actions')}
        </Table.HeadCell>
        <Table.HeadCell className="text-center">
          {t('users.user-level')}
        </Table.HeadCell>
      </Table.Head>
      <Table.Body className=" divide-y">
        {data?.logs.map(({ id, createdAt, reference, type, user }) => {
          const redirectTo = getRecordTypeBasedOnEventType(type);
          const mappedRoles = user.roles
            .filter((role) => role.value !== undefined)
            .map((role) => role.value as UserGroupDto);

          return (
            <Table.Row
              key={id.value}
              className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
            >
              <Table.Cell className="text-center">
                {user.name.value
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
              </Table.Cell>
              <Table.Cell className="text-center">
                {user.email.value}
              </Table.Cell>
              <Table.Cell className="text-center">
                {formatDate(createdAt)}
              </Table.Cell>
              <Table.Cell className="text-center">
                {redirectTo === 'other' ? (
                  <button
                    onClick={() => handleOpenModal(reference.value)}
                    className="hover:underline text-blue-600"
                  >
                    {translateEvents(type)}
                  </button>
                ) : redirectTo ? (
                  <Link
                    className="hover:underline text-blue-600"
                    to={`/${redirectTo}/${encodeURIComponent(reference.value)}`}
                  >
                    {translateEvents(type)}
                  </Link>
                ) : (
                  translateEvents(type)
                )}
              </Table.Cell>
              <Table.Cell className="text-center">
                <MapUserLevels roles={mappedRoles} />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>

      <Modal
        isVisible={modalIsVisible}
        toggleVisibility={toggleModalVisibility}
      >
        <div className="p-6 min-w-[500px] ">
          <h2 className="mx-4 text-lg font-semibold">
            {t('disturbance-cleanup.disturbance-cleanup-success')}
          </h2>
          <div className=" mx-4 mb-4 text-center">
            {selectedReferences.length}
          </div>
          <ul>
            {selectedReferences.map(({ userId, phoneNumber }, index) => (
              <li key={index} className="mb-2 bg-gray-100 rounded">
                <Link to={`/customer/${userId}`} className=" text-blue-800">
                  {phoneNumber}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default AuditLog;
