import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { subDays } from 'date-fns';
import Header from '~/components/Header';
import { useMutation, useQueryClient } from 'react-query';
import { useUnblockRemoveWarningsCustomersMutation } from '~/lib/hooks/useUnblockRemoveWarningsCustomersMutation';
import { mapOperator } from '~/features/search/utils';
import { useModal } from '~/hooks/useModal';
import { NoteModal } from '~/components/Modal/NoteModal';
import { SearchSchemaType } from '~/lib/schemas/searchSchema';

const operators: SearchSchemaType['operators'] = [
  'dk.telia',
  'dk.sonofon',
  'dk.tdc',
  'dk.hi3g',
];

const DisturbanceCleanup = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { unblockRemoveWarningsCustomers } =
    useUnblockRemoveWarningsCustomersMutation();

  // Prepare form methods
  const methods = useForm({
    defaultValues: {
      startDate: subDays(new Date(), 1).toISOString(),
      endDate: new Date().toISOString(),
      operator: '',
    },
  });

  const [isModalOpen, setIsModalOpen] = useModal();

  const { handleSubmit, control, reset } = methods;

  const onSubmit = (data: {
    startDate: string;
    endDate: string;
    operator: string;
    note: string;
  }) => {
    if (!data.operator || !data.endDate || !data.startDate || !data.note) {
      return;
    }

    unblockRemoveWarningsCustomers.mutate({
      startDate: data.startDate,
      endDate: data.endDate,
      operator: data.operator,
      note: data.note,
    });
  };

  return (
    <div className="flex flex-col flex-1 p-2 overflow-x-auto">
      <div className="flex items-center justify-between">
        <Header size="2xl">{t('maintenance.disturbance-cleanup')}</Header>
      </div>
      <p>{t('disturbance-cleanup.disturbance-cleanup-description')}</p>
      <FormProvider {...methods}>
        <form className="mt-4 space-y-4">
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <div className="font-medium">{t('tables.operator')}</div>
            </div>

            <Controller
              name="operator"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className="flex items-center gap-4">
                  {operators.map((operator) => (
                    <div key={operator} className="flex items-center">
                      <input
                        type="radio"
                        id={operator}
                        name="operator"
                        value={operator}
                        checked={value === operator}
                        onChange={() => onChange(operator)}
                        className="mr-2"
                      />
                      <label htmlFor={operator}>{mapOperator(operator)}</label>
                    </div>
                  ))}
                </div>
              )}
            />
          </div>
          <div className="flex items-end gap-6">
            <Controller
              name="startDate"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
                    {t('common.start-date')}
                  </label>
                  <DatePicker
                    showTimeSelect
                    dateFormat="d/M/yyyy, HH:mm"
                    timeFormat="HH:mm"
                    onChange={(date) => {
                      if (date) onChange(date.toISOString());
                    }}
                    selected={value ? new Date(value) : null}
                    calendarStartDay={1}
                  />
                </div>
              )}
            />

            <Controller
              name="endDate"
              control={control}
              render={({ field: { value, onChange } }) => (
                <div>
                  <label className="dark:text-gray-300 block mb-2 text-sm font-medium text-gray-900">
                    {t('common.end-date')}
                  </label>
                  <DatePicker
                    showTimeSelect
                    dateFormat="d/M/yyyy, HH:mm"
                    timeFormat="HH:mm"
                    onChange={(date) => {
                      if (date) onChange(date.toISOString());
                    }}
                    selected={value ? new Date(value) : null}
                    calendarStartDay={1}
                  />
                </div>
              )}
            />
          </div>

          <div className="flex items-center gap-6 mt-4">
            <button
              disabled={!methods.watch('operator')}
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen();
              }}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {t('common.submit')}
            </button>
            <NoteModal
              isVisible={isModalOpen}
              toggleVisibility={setIsModalOpen}
              isLoading={false}
              onSubmit={(note) => {
                const formData = methods.getValues();
                const submitData = {
                  ...formData,
                  note: note,
                };

                onSubmit(submitData);
                setIsModalOpen();
              }}
              submitText={t('common.submit')}
              title={t('notes.customer.unblock.title')}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default DisturbanceCleanup;
