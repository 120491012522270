import React from 'react';
import ReactDOM from 'react-dom';

export type ModalProps = {
  isVisible: boolean;
  toggleVisibility: () => void;
  children: React.ReactNode;
};

export const Modal = ({
  isVisible,
  toggleVisibility,
  children,
}: Readonly<ModalProps>): JSX.Element | null => {
  const modal: JSX.Element = (
    <>
      <div
        className="bg-white/60 fixed top-0 bottom-0 left-0 right-0"
        onClick={toggleVisibility}
      />
      <div
        className="left-1/2 top-1/2 fixed flex flex-col items-center justify-center text-center -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-500 rounded-md shadow-md max-h-[90vh]"
        aria-modal
        aria-label="Modal Details"
        role="dialog"
      >
        <div className="relative w-full">
          <span
            className="modal-close text-end right-4 absolute z-10 flex justify-end mt-4 cursor-pointer"
            aria-label="Close Modal Details"
            onClick={toggleVisibility}
          >
            &times;
          </span>
        </div>
        <div className="overflow-y-auto w-full max-h-[calc(90vh-100px)]">
          {children}
        </div>
      </div>
    </>
  );
  return isVisible ? ReactDOM.createPortal(modal, document.body) : null;
};
