import { Spinner, Table } from 'flowbite-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import Button from '../../components/Button';
import { OperatorCell } from '../search/components/TicketCells';
import { FullUserResultsTable } from './FullUserResultsTable';
import { CustomerNotesTable } from './CustomerNotesTable';
import { AddCustomerNoteCell } from './AddNoteCell';
import { BlockadesTable } from './BlockadesTable';
import { createBlockadesData } from './BlockadesTable/createBlockadesData';
import { useUserQuery } from '~/lib/hooks/useUserQuery';
import { CustomerProfileBlockActions } from './CustomerProfileBlockActions';
import { useQueryClient } from 'react-query';
import { Container } from '~/components/Layout';

const CustomerProfile = () => {
  const params = useParams<{ customerId: string }>();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data, isLoading, isSuccess } = useUserQuery(params.customerId ?? '', {
    enabled: Boolean(params.customerId),
  });

  const blockades = useMemo(() => {
    if (!data) {
      return [];
    }

    return createBlockadesData(data.user);
  }, [data]);

  const { t } = useTranslation();

  if (!isSuccess) {
    return null;
  }

  const { user } = data;

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  return (
    <Container>
      <div className="flex justify-between">
        <Button className="w-auto" type="gray" onClick={() => navigate(-1)}>
          {t('common.back')}
        </Button>
        <Button
          className="w-auto"
          onClick={() => {
            queryClient.invalidateQueries({ stale: true });
          }}
        >
          {t('common.refresh')}
        </Button>
      </div>
      <div className="py-4 text-xl font-semibold">
        {t('tables.customer-profile')}
      </div>

      <div className="md:flex-row flex flex-col gap-4 pb-4">
        <div className="flex flex-col">
          <Table className="shadow-lg">
            <Table.Head className="bg-gray-100">
              <Table.HeadCell>{t('customer.telephone-number')}</Table.HeadCell>
              <Table.HeadCell>{t('customer.customer-id')}</Table.HeadCell>
              <Table.HeadCell>{t('customer.block-status')}</Table.HeadCell>
              <Table.HeadCell>{t('customer.warning-count')}</Table.HeadCell>
              <Table.HeadCell>
                {t('customer.last-used-operator')}
              </Table.HeadCell>
              <Table.HeadCell>
                {t('customer.confirmed-purchases')}
              </Table.HeadCell>
              <Table.HeadCell>
                {t('customer.unconfirmed-purchases')}
              </Table.HeadCell>
              <Table.HeadCell>{t('customer.stopped-orders')}</Table.HeadCell>
              <Table.HeadCell>{t('customer.money-spent')}</Table.HeadCell>
              <Table.HeadCell>{t('customer.note')}</Table.HeadCell>
              <Table.HeadCell>{t('customer.block-unblock')}</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              <Table.Row className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-center text-gray-900 bg-white">
                <Table.Cell className="border-2 border-gray-100">
                  {user.phoneNumber.value}
                </Table.Cell>

                <Table.Cell className="border-2 border-gray-100">
                  {String(user.userId.value)}
                </Table.Cell>

                <Table.Cell className="border-2 border-gray-100">
                  {user.isBlocked ? t('common.yes') : t('common.no')}
                </Table.Cell>
                <Table.Cell className="border-2 border-gray-100">
                  {user.warningCount}
                </Table.Cell>
                <OperatorCell
                  operator={user.provider}
                  className="border-2 border-gray-100"
                />

                <Table.Cell className="border-2 border-gray-100">
                  {user.purchasedTickets}
                </Table.Cell>

                <Table.Cell className="border-2 border-gray-100">
                  {user.uncompletedOrders}
                </Table.Cell>

                <Table.Cell className="border-2 border-gray-100">
                  {user.stoppedOrders}
                </Table.Cell>

                <Table.Cell className="border-2 border-gray-100">
                  {user.moneySpent}
                </Table.Cell>
                <AddCustomerNoteCell userId={user.userId.value} />

                <Table.Cell className="border-2 border-gray-100">
                  <CustomerProfileBlockActions user={user} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
      <BlockadesTable blockades={blockades} />
      <CustomerNotesTable customerId={user.userId.value.toString()} />
      <FullUserResultsTable />
    </Container>
  );
};
export default CustomerProfile;
