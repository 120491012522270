import { useTranslation } from 'react-i18next';
import type { EventLogTypeDto } from '~/lib/dtos/common/EventLogTypeDto';

export const useTranslateEventLogTypes = () => {
  const { t } = useTranslation();

  const eventLogMap: Record<EventLogTypeDto, string> = {
    ADD_NOTE_TO_CUSTOMER: t('event-log.add-note-to-customer'),
    ADD_NOTE_TO_TICKET: t('event-log.add-note-to-ticket'),
    BLOCK_CUSTOMER: t('event-log.block-customer'),
    CREATE_USER_PROFILE: t('event-log.create-user-profile'),
    DEACTIVATE_USER_PROFILE: t('event-log.deactivate-user-profile'),
    DELETE_BULLETIN: t('event-log.delete-bulletin'),
    EDIT_USER_PROFILE: t('event-log.edit-user-profile'),
    PUBLISH_BULLETIN: t('bulletins.publish-bulletin'),
    REACTIVATE_USER_PROFILE: t('event-log.reactivate-user-profile'),
    REFUND: t('event-log.refund'),
    RESEND_PASSWORD: t('event-log.resend-password'),
    RESEND_TICKET: t('event-log.resend-ticket'),
    UNBLOCK_CUSTOMER: t('event-log.unblock-customer'),
    ADD_INSTRUCTION: t('event-log.add-instructions'),
    DELETE_INSTRUCTION: t('event-log.delete-instructions'),
    SEND_RECEIPT: t('event-log.send-receipt'),
    DELETE_USER: t('event-log.delete-user'),
    EDIT_BULLETIN: t('bulletins.edit-bulletin'),
    CHANGE_USER_LEVEL: t('event-log.change-user-level'),
    MOVE_DSB_QUEUE: t('event-log.move-dsb-queue'),
    MOVE_DSB_REFUND_QUEUE: t('event-log.move-dsb-refund-queue'),
    DECREASE_WARNING: t('event-log.decrease-warning'),
    UNBLOCK_AND_DECREASE_WARNINGS_PER_OPERATORS: t(
      'event-log.unblock-and-decrease-warnings-per-operators'
    ),
  };

  const translateEvents = (event: EventLogTypeDto) => {
    return eventLogMap[event] ?? event;
  };

  return { translateEvents };
};
